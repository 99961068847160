<template lang="pug">
div.card-container(@click="$router.push('/offers/detail/' + provider.uid)")
    div.img-container
        img(:src="wallpaper" alt="Provider picture")
    p.price Prix moyen: €€ {{ provider.initialCost }}
    p.name {{ provider.name }}
    //- p.adress Adresse de la PF
</template>

<script>
import { Storage } from "./../../firebase/Storage";

export default {
    props: ["provider"],

    data() {
        return {
            wallpaper: false
        }
    },

    mounted() {
        Storage.ref(this.provider.wallpaper)
            .getDownloadURL()
            .then((url) => {
                this.wallpaper = url;
            });
    },
};
</script>

<style lang="scss" scoped>
.card-container {
    height: 245px;
    width: 350px;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    transition: .15s;
    &:hover {
        transition: .15s;
        background: rgba(200,200,200,.1);
        .img-container {
            opacity: .7;
        }
    }
    .img-container {
        height: 180px;
        width: 100%;
        background: var(--greyl);
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        transition: .15s;
        img {
            width: 100%;
        }
    }
    .price {
        margin: 15px 10px 0 0px;
        font-size: var(--fsmall);
        color: var(--yellow);
    }
    .name {
        margin-top: 10px;
        font-weight: bold;
        font-size: var(--fmedium);
    }
    .adress {
        margin-top: 5px;
        opacity: .8;
        font-size: var(--fsmall);
    }
}
</style>